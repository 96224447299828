// import { Box, FlexBox, Text } from "red-design/atoms";
import { syringPumpDataConfig, ventilatorDataConfig } from "./otherVitals.constants";
import { useOthervitalsStore, syringeStore } from "../../../../../store/vitalsStore";
import { useEffect, useState } from "react";
import { CardWithTitle } from "@/pages/dashboard/case_details/components/cards/DetailsCards";
import Icon from "@/components/global/icon/Icon";

const OtherVitalDetails = () => {

    const [ventilatorData, setVentilatorData] = useState<any>(ventilatorDataConfig);
    const [syringeData, setSyringeData] = useState<any>(syringPumpDataConfig);


    const {otherVitalsState} = useOthervitalsStore((state: any)=> {return {...state}});
    const { syringePumpState } = syringeStore((state: any)=> {return {...state}});

    useEffect(()=> {
        setSyringeData((prev:any)=> {
            return prev.map((entry: any)=> {
                const newVal = syringePumpState.find((val: any)=> {
                    return val.key === entry.key
                })
                    return ({
                        ...entry,
                        value: newVal ?  newVal.value : '--'
                    })
            })
        })
    }, [syringePumpState])

    useEffect(()=> {
        setVentilatorData((prev:any)=> {
            return prev.map((entry: any)=> {
                const newVal = otherVitalsState.find((val: any)=> {
                    return val.key === entry.key
                })
                    return ({
                        ...entry,
                        value: newVal ?  newVal.value : '--'
                    })
            })
        })
    }, [otherVitalsState])
    
    return (
<div className="pb-2">
      <div className="pl-2 pt-2">
        <div>
        <CardWithTitle
          title={<div className="flex items-center justify-center p-1">
                <div className="text-2xl">
                <Icon  icon='healthicons:ventilator-alt-outline' color='#161B1F'/>
                </div>
                <div className="ml-3"> {"Ventilator"} </div>
            </div>}
          className={'text-center w-full mt-3'}
          styles={{
            backgroundColor: '#F7F7F7',
            padding: '0.25rem',
          }}
          >
          <div className="px-4 py-2 bg-white mt-1">
            <div className="flex justify-between items-center w-full">
              {ventilatorData.map((vital, index) => (
                <div key={index} className="flex flex-col justify-between items-center">
                  <div>
                    <p className="text-center text-xs text-[#5B6169]">{vital.name}</p>
                  </div>
                  <div>
                    <p className={`text-center text-${vital.color} text-xs font-semibold`}>{vital.value}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </CardWithTitle>
        </div>
      </div>
      
      <div className="pl-2 pt-2">
        <div>
        <CardWithTitle
          title={<div className="flex items-center justify-center p-1">
                <div className="text-2xl">
                <Icon  icon='la:syringe' color='#161B1F'/>
                </div>
                <div className="ml-3"> {"Syringe Pump"} </div>
            </div>}
          className={'text-center w-full mt-3'}
          styles={{
            backgroundColor: '#F7F7F7',
            padding: '0.25rem',
          }}
          >
          <div className="px-4 py-2 bg-white mt-1">
            <div className="flex justify-between items-center w-full">
              {syringeData.map((vital, index) => (
                <div key={index} className="pr-10 min-w-max">
                  <div className="flex flex-col justify-between items-center">
                    <div>
                      <p className="text-center text-xs text-[#5B6169]">{vital.name}</p>
                    </div>
                    <div>
                      <p className={`text-center text-${vital.color} text-xs font-semibold`}>{vital.value}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </CardWithTitle>
        </div>
      </div>
    </div>
    )
}

export default OtherVitalDetails;