import { create as CreateRedStore } from 'zustand'


export const otherVitalsInitialStore = [
    { value: '--', key: 'RR' },
    { value: '--', key: 'VTe' },
    { value: '--', key: 'peep' },
    { value: '--', key: 'FiO2' },
    { value: '--', key: 'PIP' },
    { value: '--', key: 'MVe' },
    { value: '--', key: 'Vpeak' }
]

export const syringePumpInitialState = [
    { value: '--', key: 'Drug Name' },
    { value: '--', key: 'Rate' },
    { value: '--', key: 'Total' }
]

export const monitorInfoInitialState = {
    'Pulse' : '--',
    'Heart Rate': '--',
    'niSys': '--',
    'niDia': '--',
    'niMean': '--',
    'RR': '--',
    'SpO2': '--',
}


export const syringeStore = CreateRedStore<any>()((set: any) => ({
    syringePumpState: syringePumpInitialState,
    setSyringePumpState: (newVal: any) =>
    set((_state: any) => ({ syringePumpState: newVal})),
}));


export const useOthervitalsStore = CreateRedStore<any>()((set: any) => ({
    otherVitalsState: otherVitalsInitialStore,
    setOtherVitalsState: (newVal: any) =>
    set((_state: any) => ({ otherVitalsState: newVal })),
}));


